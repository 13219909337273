// **************************************** Manufacturing Constants ****************************************

// Manufacturing Dropdown Constants
export const GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST =
  "GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST";
export const GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS =
  "GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS";
export const GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE =
  "GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE";

export const GET_TO_LOCATION_DROPDOWN_LIST_REQUEST =
  "GET_TO_LOCATION_DROPDOWN_LIST_REQUEST";
export const GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS =
  "GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS";
export const GET_TO_LOCATION_DROPDOWN_LIST_FAILURE =
  "GET_TO_LOCATION_DROPDOWN_LIST_FAILURE";

export const GET_INVOICE_LIST_REQUEST = "GET_INVOICE_LIST_REQUEST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_FAILURE = "GET_INVOICE_LIST_FAILURE";

export const GET_PARTY_LIST_REQUEST = "GET_PARTY_LIST_REQUEST";
export const GET_PARTY_LIST_SUCCESS = "GET_PARTY_LIST_SUCCESS";
export const GET_PARTY_LIST_FAILURE = "GET_PARTY_LIST_FAILURE";

export const GET_SUBLOCATION_LIST_REQUEST = "GET_SUBLOCATION_LIST_REQUEST";
export const GET_SUBLOCATION_LIST_SUCCESS = "GET_SUBLOCATION_LIST_SUCCESS";
export const GET_SUBLOCATION_LIST_FAILURE = "GET_SUBLOCATION_LIST_FAILURE";

// Get Inventory Details
export const GET_INVENTORY_DETAILS_REQUEST = "GET_INVENTORY_DETAILS_REQUEST";
export const GET_INVENTORY_DETAILS_SUCCESS = "GET_INVENTORY_DETAILS_SUCCESS";
export const GET_INVENTORY_DETAILS_FAILURE = "GET_INVENTORY_DETAILS_FAILURE";
export const CLEAR_INVENTORY_DETAILS = "CLEAR_INVENTORY_DETAILS";

// View Previous Boxes
export const VIEW_PREVIOUS_BOX_REQUEST = "VIEW_PREVIOUS_BOX_REQUEST";
export const VIEW_PREVIOUS_BOX_SUCCESS = "VIEW_PREVIOUS_BOX_SUCCESS";
export const VIEW_PREVIOUS_BOX_FAILURE = "VIEW_PREVIOUS_BOX_FAILURE";

// Scan Serial Boxes
export const SCAN_SERIAL_BOX_INVENTORY_REQUEST =
  "SCAN_SERIAL_BOX_INVENTORY_REQUEST";
export const SCAN_SERIAL_BOX_INVENTORY_SUCCESS =
  "SCAN_SERIAL_BOX_INVENTORY_SUCCESS";
export const SCAN_SERIAL_BOX_INVENTORY_FAILURE =
  "SCAN_SERIAL_BOX_INVENTORY_FAILURE";

// Generate MRP LABEL
export const CREATE_MRP_LABEL_REQUEST = "CREATE_MRP_LABEL_REQUEST";
export const CREATE_MRP_LABEL_SUCCESS = "CREATE_MRP_LABEL_SUCCESS";
export const CREATE_MRP_LABEL_FAILURE = "CREATE_MRP_LABEL_FAILURE";

export const CLOSE_BOX_REQUEST = "CLOSE_BOX_REQUEST";
export const CLOSE_BOX_SUCCESS = "CLOSE_BOX_SUCCESS";
export const CLOSE_BOX_FAILURE = "CLOSE_BOX_FAILURE";

export const CREATE_BOX_REQUEST = "CREATE_BOX_REQUEST";
export const CREATE_BOX_SUCCESS = "CREATE_BOX_SUCCESS";
export const CREATE_BOX_FAILURE = "CREATE_BOX_FAILURE";

export const DOWNLOAD_BOX_LABEL_REQUEST = "DOWNLOAD_BOX_LABEL_REQUEST";
export const DOWNLOAD_BOX_LABEL_SUCCESS = "DOWNLOAD_BOX_LABEL_SUCCESS";
export const DOWNLOAD_BOX_LABEL_FAILURE = "DOWNLOAD_BOX_LABEL_FAILURE";

export const GET_SKU_PRODUCTS_REQUEST = "GET_SKU_PRODUCTS_REQUEST";
export const GET_SKU_PRODUCTS_SUCCESS = "GET_SKU_PRODUCTS_SUCCESS";
export const GET_SKU_PRODUCTS_FAILURE = "GET_SKU_PRODUCTS_FAILURE";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const GET_PRODUCTS_LIST_REQUEST = "GET_PRODUCTS_LIST_REQUEST";
export const GET_PRODUCTS_LIST_SUCCESS = "GET_PRODUCTS_LIST_SUCCESS";
export const GET_PRODUCTS_LIST_FAILURE = "GET_PRODUCTS_LIST_FAILURE";
