import { combineReducers } from "redux";
import { LoginUserReducers } from "../Reducers/LoginReducers";
import {
  DownloadBoxLabelReducers,
  GetSkuProductsReducers,
  GetUserProfileReducers,
  ManufacturingPackingDropdownDataReducers,
  closeBoxReducers,
  createBoxReducers,
  createMRPLabelReducers,
  getInventoryDetailsReducers,
  getProductListReducers,
  scanSerialBoxReducers,
  viewPreviousBoxReducers,
} from "../Reducers/ProductionReducers";

const rootReducer = combineReducers({
  login: LoginUserReducers,
  manufacturingDropdown: ManufacturingPackingDropdownDataReducers,
  InventoryShow: getInventoryDetailsReducers,
  ShowPreviousBox: viewPreviousBoxReducers,
  scanSerialBoxes: scanSerialBoxReducers,
  MRPLabelPdf: createMRPLabelReducers,
  ClosingBox: closeBoxReducers,
  createBox: createBoxReducers,
  boxLabel: DownloadBoxLabelReducers,
  products: GetSkuProductsReducers,
  userProfile: GetUserProfileReducers,
  productsList: getProductListReducers,
});

export default rootReducer;
