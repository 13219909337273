import { CLEAR_ERRORS } from "../Constants/LoginConstants";
import {
  CLEAR_INVENTORY_DETAILS,
  CLOSE_BOX_FAILURE,
  CLOSE_BOX_REQUEST,
  CLOSE_BOX_SUCCESS,
  CREATE_BOX_FAILURE,
  CREATE_BOX_REQUEST,
  CREATE_BOX_SUCCESS,
  CREATE_MRP_LABEL_FAILURE,
  CREATE_MRP_LABEL_REQUEST,
  CREATE_MRP_LABEL_SUCCESS,
  DOWNLOAD_BOX_LABEL_FAILURE,
  DOWNLOAD_BOX_LABEL_REQUEST,
  DOWNLOAD_BOX_LABEL_SUCCESS,
  GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE,
  GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST,
  GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS,
  GET_INVENTORY_DETAILS_FAILURE,
  GET_INVENTORY_DETAILS_REQUEST,
  GET_INVENTORY_DETAILS_SUCCESS,
  GET_INVOICE_LIST_FAILURE,
  GET_INVOICE_LIST_REQUEST,
  GET_INVOICE_LIST_SUCCESS,
  GET_PARTY_LIST_FAILURE,
  GET_PARTY_LIST_REQUEST,
  GET_PARTY_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_SKU_PRODUCTS_FAILURE,
  GET_SKU_PRODUCTS_REQUEST,
  GET_SKU_PRODUCTS_SUCCESS,
  GET_SUBLOCATION_LIST_FAILURE,
  GET_SUBLOCATION_LIST_REQUEST,
  GET_SUBLOCATION_LIST_SUCCESS,
  GET_TO_LOCATION_DROPDOWN_LIST_FAILURE,
  GET_TO_LOCATION_DROPDOWN_LIST_REQUEST,
  GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  SCAN_SERIAL_BOX_INVENTORY_FAILURE,
  SCAN_SERIAL_BOX_INVENTORY_REQUEST,
  SCAN_SERIAL_BOX_INVENTORY_SUCCESS,
  VIEW_PREVIOUS_BOX_FAILURE,
  VIEW_PREVIOUS_BOX_REQUEST,
  VIEW_PREVIOUS_BOX_SUCCESS,
} from "../Constants/ProductionConstants";

// Manufacturing Dropdown Data List Reducers
export const ManufacturingPackingDropdownDataReducers = (
  state = {
    from_location: {},
    to_location: {},
    invoice_list: {},
    party_list: {},
    sub_location: {},
  },
  action
) => {
  switch (action.type) {
    case GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST:
    case GET_TO_LOCATION_DROPDOWN_LIST_REQUEST:
    case GET_INVOICE_LIST_REQUEST:
    case GET_PARTY_LIST_REQUEST:
    case GET_SUBLOCATION_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        from_location: action.payload,
      };
    case GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        to_location: action.payload,
      };
    case GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice_list: action.payload,
      };
    case GET_PARTY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        party_list: action.payload,
      };
    case GET_SUBLOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sub_location: action.payload,
      };
    case GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE:
    case GET_TO_LOCATION_DROPDOWN_LIST_FAILURE:
    case GET_INVOICE_LIST_FAILURE:
    case GET_PARTY_LIST_FAILURE:
    case GET_SUBLOCATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Inventory Details Reducers
export const getInventoryDetailsReducers = (
  state = { inventory_details: {} },
  action
) => {
  switch (action.type) {
    case GET_INVENTORY_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_INVENTORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        inventory_details: action.payload,
      };
    case GET_INVENTORY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_INVENTORY_DETAILS:
      return {
        ...state,
        inventory_details: {},
      };
    default:
      return state;
  }
};

// Get Table List/Product List Reducers
export const getProductListReducers = (
  state = { products_list: {} },
  action
) => {
  switch (action.type) {
    case GET_PRODUCTS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products_list: action.payload,
      };
    case GET_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// View Previous Box Reducers
export const viewPreviousBoxReducers = (
  state = { previous_box: {} },
  action
) => {
  switch (action.type) {
    case VIEW_PREVIOUS_BOX_REQUEST:
      return {
        loading: true,
      };
    case VIEW_PREVIOUS_BOX_SUCCESS:
      return {
        ...state,
        loading: false,
        previous_box: action.payload,
      };
    case VIEW_PREVIOUS_BOX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Scan Serial Box Reducers
export const scanSerialBoxReducers = (
  state = { scan_serial_box: {} },
  action
) => {
  switch (action.type) {
    case SCAN_SERIAL_BOX_INVENTORY_REQUEST:
      return {
        loading: true,
      };
    case SCAN_SERIAL_BOX_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        scan_serial_box: action.payload,
      };
    case SCAN_SERIAL_BOX_INVENTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create MRP Label Reducers
export const createMRPLabelReducers = (state = { mrp_label: {} }, action) => {
  switch (action.type) {
    case CREATE_MRP_LABEL_REQUEST:
      return {
        loading: true,
      };
    case CREATE_MRP_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        mrp_label: action.payload,
      };
    case CREATE_MRP_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Close Box Reducers
export const closeBoxReducers = (state = { close_box: {} }, action) => {
  switch (action.type) {
    case CLOSE_BOX_REQUEST:
      return {
        loading: true,
      };
    case CLOSE_BOX_SUCCESS:
      return {
        ...state,
        loading: false,
        close_box: action.payload,
      };
    case CLOSE_BOX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Box Reducers
export const createBoxReducers = (state = { create_Box: {} }, action) => {
  switch (action.type) {
    case CREATE_BOX_REQUEST:
      return {
        loading: true,
      };
    case CREATE_BOX_SUCCESS:
      return {
        ...state,
        loading: false,
        create_Box: action.payload,
      };
    case CREATE_BOX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Download Box Label Reducers
export const DownloadBoxLabelReducers = (state = { box_label: {} }, action) => {
  switch (action.type) {
    case DOWNLOAD_BOX_LABEL_REQUEST:
      return {
        loading: true,
      };
    case DOWNLOAD_BOX_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        box_label: action.payload,
      };
    case DOWNLOAD_BOX_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get SKU Products Reducers
export const GetSkuProductsReducers = (
  state = { SKU_products: {} },
  action
) => {
  switch (action.type) {
    case GET_SKU_PRODUCTS_REQUEST:
      return {
        loading: true,
      };
    case GET_SKU_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        SKU_products: action.payload,
      };
    case GET_SKU_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get User Profile Reducers
export const GetUserProfileReducers = (
  state = { user_profile: {} },
  action
) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return {
        loading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user_profile: action.payload,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
