import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.scss";
import { Suspense } from "react";
import ProtectedRoutes from "./Middleware/ProtectedRoutes";
import Loading from "./Components/Loading/Loading";

const Login = lazy(() => import("./Pages/Login"));
const ManufacturingPacking = lazy(() => import("./Pages/ManufacturingPackage"));

const App = () => {
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  return (
    <Router>
      <Suspense
        fallback={
          <Loading color={"rgba(1, 18, 34, 1)"} size={42} height={true} />
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<ManufacturingPacking />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
